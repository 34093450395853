import StandardButton from "../components/UI/buttons/StandardButton";
import MainContentWrapper from "../components/UI/pageStructure/MainContentWrapper";
import GanttChartTabBar from "../components/ganttChart/filtering/GanttChartTabBar";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import HorizontalGanttWrapper from "../components/ganttChart/HorizontalGanttWrapper";
import VerticalGanttWrapper from "../components/ganttChart/VerticalGanttWrapper";
import { initiateGanttChartData } from "../store/ganttChart/action";
import Drawer from "../components/UI/Drawer";
import DrawerContent from "../components/drawer/DrawerContent";
import PondooPlanStatusBar from "../components/blackRoundInfoComponents/PondooPlanStatusBar";
import SearchBar from "../components/search/SearchBar";
import ZoomComponent from "../components/ganttChart/ZoomComponent";

export default function GanttChartPage() {
  const dispatch = useAppDispatch();
  const { isDrawerOpen } = useAppSelector((state) => state.modalSlice);
  const { ganttChartDirection, ganttChartData, showSpinner, error } =
    useAppSelector((state) => state.ganttChartSlice);
  const { timelineRange } = useAppSelector((state) => state.timelineSlice);
  const { selectedNexusFilterDate, selectedActiveNexusFilter } = useAppSelector(
    (state) => state.nexusFiltersSlice
  );

  return (
    <>
      <MainContentWrapper classes="flex flex-col">
        {/* TODO @Tanja: Fix handling of this, when websocket is implemented */}
        {timelineRange && (
          <article className="w-full flex justify-between items-center p-3 min-h-[100px]">
            {/* <div className="flex justify-between items-center"> */}
            <GanttChartTabBar />
            {ganttChartData && (
              <div className="flex gap-spacing-md items-center">
                <SearchBar
                  employees={ganttChartData.employees}
                  unplannedTasks={
                    ganttChartData.generalGanttChartInfo.unplannedTasks
                      .workTasks
                  }
                />
              </div>
            )}
            {/* </div> */}
          </article>
        )}
        {!ganttChartData && !showSpinner && (
          <div className="flex flex-col gap-[10px] items-center justify-center h-full">
            {error ? (
              <p style={{ color: "var(--col-dirt-rose-dark)" }}>{error}</p>
            ) : (
              <p>
                <b>Vi mangler en plan for i dag.</b> <br />
                Tryk på knappen herunder for at hente den.
              </p>
            )}
            <StandardButton
              classes="w-[300px]"
              onClick={() =>
                dispatch(
                  initiateGanttChartData({
                    filterId: selectedActiveNexusFilter!.id,
                    date: selectedNexusFilterDate!,
                  })
                )
              }
              variant="SECONDARY"
              disable={!selectedActiveNexusFilter}
            >
              {/* TODO @tanja - vi kan have en Nexus plan men ikke en Pondoo plan, så hvis vi er på Pondoo tab'en, så skal onClick nok egentlig bare starte optimeringen særskilt??*/}
              Hent data
            </StandardButton>
          </div>
        )}
        {/*  TODO: @Tanja this should probably be a component */}
        {showSpinner && (
          <div
            className="flex items-center justify-center"
            style={{ width: "100%", height: "100%" }}
          >
            <img
              src={require("../assets/icons/spinner.gif")}
              alt={"Loading..."}
              style={{ height: 150, width: 150 }}
            />
          </div>
        )}

        {ganttChartData && ganttChartDirection === "HORIZONTAL" && (
          <HorizontalGanttWrapper ganttChartData={ganttChartData} />
        )}
        {ganttChartData &&
          !showSpinner &&
          ganttChartDirection === "VERTICAL" && <VerticalGanttWrapper />}
      </MainContentWrapper>

      {isDrawerOpen && (
        <Drawer>
          <DrawerContent />
        </Drawer>
      )}
      <PondooPlanStatusBar />
      <ZoomComponent />
    </>
  );
}
