import textStyles from "../../../styles/UI/textComponents.module.css";

const MediumHeader = ({
  children,
  classes,
}: {
  children: React.ReactNode;
  classes?: string;
}) => {
  // todo: check against design
  const className = `${textStyles.mediumHeader} ${!!classes ? classes : ""}`;
  return <p className={className}>{children}</p>;
};

export default MediumHeader;
