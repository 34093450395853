class labels {
  static adress = "Adresse";
  static citizens = "Borgere";
  static citizen = "Borger";
  static visit = "Besøg";
  static visitHours = "Besøgstid";
  static visitDetails = "Besøgdetaljer";
  static employees = "Medarbejdere";
  static employee = "Medarbejder";
  static minutes = "Minutter";
  static hours = "Timer";
  static details = "Detaljer";
  static dependencies = "Afhængigheder";
  static task = "Ydelse";
  static tasks = "Ydelser";
  static priority = "Prioritet";
  static citizenDetails = "Borgerdetaljer";
  static skillRequirement = "Kompetencekrav";
  static skills = "Kompetencer";
  static time = "Tid";
  static expenses = "Omkostninger";
  static keyNumbers = "Andre nøgletal";
  static citizenService = "Borgerservice";
  static plannedTime = "Planlagt tid";
  static plannedEmployees = "Planlagte medarbejdere";
  static unplannedEmployees = "Uplanlagte medarbejdere";
  static timeWithCitizens = "Borgertid";
  static otherTasks = "Andre kalenderaftaler";
  static transportation = "Kørsel";
  static plan = "Vagtplan";
  static overtime = "Overtid";
  static unplannedTime = "Uplanlagt tid";
  static affinity = "Kontinuitet";
  static filter = "Filtre";
  static AIMissing = "AI planlægning mangler";
  static AICompleted = "AI planlægning er færdig";
  static search = "Søg";
  static choosePeriod = "Vælg periode";
  static locked = "Låst på medarbejder";
  static nexusRules = "Regler fra Nexus";
  static blocked = "Borger undtaget fra kontakt";
  static score = "Score";
  static rules = "Regler";
  static competencies = "Kompetencer";
  static assignedEmployee = "Tildelt medarbejder";
  static preferences = "Præferencer";
  static high = "Høj";
  static normal = "Normal";
  static searchPrompt = "Søg borger eller ydelse";
  static compareWithNexus = "Sammenlign med original";

  // Task Dependencies
  static isDependencyOfTitle = "Besøget har følgende tilknyttede besøg";
  static dependsOnTitle = "Besøget er tilknyttet følgende primære besøg";
  static startTimeColumnLabel = "Opstart";
  static endTimeColumnLabel = "Slut";

  // Violations
  static violationSkill =
    "Medarbejderen opfylder ikke alle kompetencekrav på opgaven";
  static violationDependency = "En tids sammenkædning er ikke overholdt";
  static violationDependencyUnplanned = "Et sammenkædet besøg er uplanlagt";
  static violationDependencySameEmployee =
    "En samme medarbejder sammenkædning er ikke overholdt";
  static violationDependencyTime =
    "En tidsmæssig sammenkædning er ikke overholdt";
  static violationOverdue = "Det tilladte sluttidspunkt er ikke overholdt";
  static violationOvertime = "Medarbejderen har for mange timer";
  static violationTravelTotalDistance =
    "Medarbejderen har for lang transport over hele dagen";
  static violationTravelTripDistance =
    "Medarbejderen har for lang transport på en enkelt tur";
  static violationTravelFirstLastTripDistance =
    "Medarbejderen har for lang transport på første eller sidste tur";
  static violationOfTotalTravelDistance =
    "Den samlede distance, som medarbejderen har rejst, overstiger den tilladte grænse.";
  static violationOfTravelTripDistance =
    "Afstanden, som medarbejderen har rejst på en enkelt tur, overstiger den tilladte grænse.";
  static violationOfFirstLastTripDistance =
    "Afstanden, som medarbejderen har rejst på den første eller sidste tur, overstiger den tilladte grænse.";
  static vilationBlockedEmployee =
    "Borgeren er undtaget fra kontakt ved den planlagte medarbejder";

  // Violation messages
  static unspecifiedViolationMessage =
    "En uspecificeret regel er ikke blevet overholdt.";
  static dependencyMinOverlapViolationMessage = (minutes: string) =>
    `Besøgene skal overlappe med mindst ${minutes} minutter.`;
  static sameEmployeeViolationMessage =
    "Besøgene skal udføres af samme medarbejder.";
  static maxDelayTimeViolationMessage = (
    minutes: string,
    anchor: "START" | "END"
  ) =>
    `Det tilknyttede besøg skal starte højest ${minutes} minutter efter det primære besøg ${anchor === "END" ? "slutter" : "starter"}.`;
  static minDelayTimeViolationMessage = (
    minutes: string,
    anchor: "START" | "END"
  ) =>
    `Det tilknyttede besøg skal starte mindst ${minutes} minutter efter det primære besøg ${anchor === "END" ? "slutter" : "starter"}.`;
  static sameStartViolationMessage = "Besøgene skal starte samtidigt.";
  static startRightAfterViolationMessage =
    "Det tilknyttede besøg skal starte umiddelbart efter det primære besøg.";
  // buttons
  static sinceNexusData = "Siden Nexus-data";
  static sendNexusData = "Send plan til Nexus";
  static UpdatePlan = "Opdater planlægning";

  // login page
  static login = "Log ind";

  static hoverEmployee = "Medarbejder";
  static hoverTasks = "Ydelser";

  static pondooCompleted = (duration: string) => {
    return `Pondoo plan opdateret ${duration} siden`;
  };
  static pondooNotUpdated = "Pondoo plan ikke opdateret";
  static pondooMissing = "Pondoo plan mangler";
  static pondooStatusError = "Pondoo plan fejlede";
  static pondooStatusCalculating = "Beregner...";
  static pondooNexusLandingError = "Noget gik galt - var der data på filteret?";
  static pondooRunning = "Kører...";
  static tryAgain = "Prøv igen";
  static update = "Opdater";

  static nexusLandingDataAlreadyExistsWarningHeader = "Er du sikker?";
  static nexusLandingDataAlreadyExistsWarningBody =
    "Der er allerede blevet hentet data fra Nexus for denne kalender og dato. Hvis du trykker videre herfra, vil eksisterende data i Pondoo blive overskrevet. Er du sikker på at du vil fortsætte?";
  static nexusLandingPageGoToExistingButton = "Gå til eksisterende plan";
  static nexusLandingPageOverwriteData = "Overskriv Pondoo data";

  // Time units
  static dayAmount = (amount: number): string =>
    `${amount} ${amount === 1 ? "dag" : "dage"}`;
  static hoursAmount = (amount: number): string =>
    `${amount} ${amount === 1 ? "time" : "timer"}`;
  static minutesAmount = (amount: number): string =>
    `${amount} ${amount === 1 ? "minut" : "minutter"}`;
  static secondsAmount = (amount: number): string =>
    `${amount} ${amount === 1 ? "sekund" : "sekunder"}`;
}

export default labels;
