import { GanttChartData, WorkTask } from "../types/ganttChart";
import { FullTimelineRange } from "../types/timeline";
import { ganttChartVerticalValues } from "../utils/ganttChartValues";

export type GroupedUnplannedTasks = {
  fullDayTasks: WorkTask[];
  shorterTasks: WorkTask[];
};

export type Interval = {
  start: number;
  end: number;
  task: WorkTask;
};

export type LayoutItem = Interval & {
  column: number;
};

export type GroupedTask = WorkTask & {
  ganttChartPosition: number;
};

const getHoursBetweenDates = (date1: Date, date2: Date): number => {
  const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
  const hours = diffInMilliseconds / (1000 * 60 * 60);
  return Number(hours.toFixed(2));
};

class UnplannedTaskChartPositionAllocationService {
  /**
   * Separate tasks into full day tasks and tasks with a shorter duration
   * @param tasks
   * @param timelineRange
   */
  public static separateFullDayTasks = (
    tasks: WorkTask[],
    timelineRange: FullTimelineRange
  ): GroupedUnplannedTasks => {
    const fullDayTasks: WorkTask[] = [];
    const shorterTasks: WorkTask[] = [];

    if (tasks.length === 0) return { fullDayTasks, shorterTasks };

    const timeOfFirstWorkHour = new Date(tasks[0].earliestStart);
    timeOfFirstWorkHour.setHours(0);
    const timeOfLastWorkHour = new Date(timeOfFirstWorkHour);
    timeOfLastWorkHour.setHours(
      timeOfFirstWorkHour.getHours() + timelineRange.fullDayHours
    );

    const workDayLength = getHoursBetweenDates(
      timeOfFirstWorkHour,
      timeOfLastWorkHour
    );

    for (const task of tasks) {
      const earliest = new Date(task.earliestStart);
      const latest = new Date(task.latestEnd);
      const taskDuration = getHoursBetweenDates(earliest, latest);

      if (earliest <= timeOfFirstWorkHour && taskDuration >= workDayLength) {
        fullDayTasks.push(task);
      } else {
        shorterTasks.push(task);
      }
    }

    return { fullDayTasks, shorterTasks };
  };

  /**
   * Cluster unplannedtasks together in groups if they overlap each other
   * @param tasks
   */
  public static clusterOverlappingTasks = (
    tasks: WorkTask[],
    startExtractor: (task: WorkTask) => Date = (task) =>
      new Date(task.earliestStart),
    endExtractor: (task: WorkTask) => Date = (task) => new Date(task.latestEnd)
  ): GroupedTask[] => {
    const intervals = tasks.map((task) => {
      const start = startExtractor(task);
      const end = endExtractor(task);

      return {
        start: start.getTime(),
        end: end.getTime(),
        task: task,
      };
    });

    // Sort intervals by start
    intervals.sort((a, b) => a.start - b.start);

    const layout: LayoutItem[] = [];
    const columnEndTimes: number[] = [];

    for (const interval of intervals) {
      let column = 0;

      // Find the first column where this interval can fit
      while (
        column < columnEndTimes.length &&
        columnEndTimes[column] > interval.start
      ) {
        column++;
      }

      // Update the end time for this column
      columnEndTimes[column] = interval.end;

      // Add the interval to the layout with its assigned column
      layout.push({ ...interval, column });
    }

    return layout.map((item) => ({
      ...item.task,
      ganttChartPosition: item.column,
    }));
  };

  static getGroupedTasks = (ganttChartData: GanttChartData) => {
    return UnplannedTaskChartPositionAllocationService.clusterOverlappingTasks(
      ganttChartData.generalGanttChartInfo.unplannedTasks.workTasks
    );
  };

  static getUnplannedDataPointsForPlotting = (
    ganttChartData: GanttChartData,
    svgWrapperWidth: number
  ) => {
    const numberOfEmployees = ganttChartData.employees.length;
    const groupedTasks = this.getGroupedTasks(ganttChartData);

    const highestGanttChartPosition = Math.max(
      ...groupedTasks.map((task) => task.ganttChartPosition)
    );

    const defaultWidthIfEquallyDivided =
      (svgWrapperWidth - ganttChartVerticalValues.verticalViewHeaderColumn) /
        numberOfEmployees +
      1;

    const numberOfUnplannedFittingDefaultWidth =
      defaultWidthIfEquallyDivided /
      (ganttChartVerticalValues.unplannedMinWidth + 1);

    return {
      numberOfEmployees: numberOfEmployees,
      numberOfUnplannedFittingDefaultWidth:
        numberOfUnplannedFittingDefaultWidth,
      highestGanttChartPosition: highestGanttChartPosition,
      defaultWidthIfEquallyDivided: defaultWidthIfEquallyDivided,
    };
  };
}

export default UnplannedTaskChartPositionAllocationService;
