import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";
import {
  PlanningDate,
  queueAffectedNexusLandingPlanningDate,
  resetSolutionsOnPlanningDate,
} from "../nexusFilters";
import { getPlanningDates } from "../nexusFilters/action";
import { createPendingJobWithJobId } from "../serverAsyncJobs";

enum actionTypes {
  initiateGanttChartData = "initiateGanttChartData ",
  initiateOptimization = "initiateOptimization ",
  getGanttChartData = "getGanttChartData ",
  getPondooStatus = "getPondooStatus",
  initiateNexusLandingDataExtraction = "initiateNexusLandingDataExtraction",
  updatePondooStatus = "updatePondooStatus",
}

type GetGanttChartDataParams = {
  idActiveNexusFilter: number;
  planningDate: PlanningDate;
  pondooSolution: boolean;
};

type InitiateGanttChartDataParams = {
  filterId: number;
  date: string;
};

type GetPondooStatusParams = {
  idActiveNexusFilter: number;
  planningDate: string;
  lastModified: string;
};

export type DataExtractionResponse = {
  status: number;
  idScenario?: number;
  message: string;
  jobId: string;
};

export const initiateGanttChartData = createAsyncThunk(
  actionTypes.initiateGanttChartData,
  async (params: InitiateGanttChartDataParams, thunkAPI) => {
    const data = {
      idActiveNexusFilter: params.filterId,
      planningDate: params.date.split("T")[0],
    };
    const response = await api.post<DataExtractionResponse>(
      API_PATHS.initiateNexusPlan,
      data,
    );
    if (response.status === 200) {
      // Reset IDs on that particular planning date
      thunkAPI.dispatch(resetSolutionsOnPlanningDate(params.date));
    }
    return response.data;
  },
);

export const initiateNexusLandingDataExtraction = createAsyncThunk(
  actionTypes.initiateNexusLandingDataExtraction,
  async (
    params: InitiateGanttChartDataParams & {
      pondooFilterId: number;
      filterName: string;
    },
    thunkAPI,
  ) => {
    const response = await api.post<any>(API_PATHS.nexusLanding, {
      filterId: params.filterId,
      date: params.date.split("T")[0],
      filterName: params.filterName,
    });

    if (response.status === 200) {
      // Queue a reset of IDs on that particular planning date
      thunkAPI.dispatch(queueAffectedNexusLandingPlanningDate(params.date));
      const { jobId } = response.data;
      thunkAPI.dispatch(createPendingJobWithJobId(jobId));
    }
    return response.data;
  },
);

export const initiateOptimization = createAsyncThunk(
  actionTypes.initiateOptimization,
  async (param: number) => {
    const data = {
      idScenario: param,
    };
    const response = await api.post<any>(API_PATHS.startOptimization, data);
    return response.data;
  },
);

export const stopOptimization = createAsyncThunk(
  actionTypes.initiateOptimization,
  async (param: number) => {
    const data = {
      idScenario: param,
    };
    const response = await api.post<any>(API_PATHS.stopOptimization, data);
    return response.data;
  },
);

export const getGanttChartData = createAsyncThunk(
  actionTypes.getGanttChartData,
  async (params: GetGanttChartDataParams, thunkAPI) => {
    const response = await api.get<any>(
      `${API_PATHS.getNexusPlan}?idActiveNexusFilter=${params.idActiveNexusFilter}&idPlanningDate=${params.planningDate.id}&pondooSolution=${params.pondooSolution}`,
    );
    if (response.status === 404) {
      thunkAPI.dispatch(
        initiateGanttChartData({
          filterId: params.idActiveNexusFilter,
          date: params.planningDate.planningDate,
        }),
      );
    } else {
      return response.data;
    }
  },
);

export const getPondooStatus = createAsyncThunk(
  actionTypes.getPondooStatus,
  async (params: GetPondooStatusParams) => {
    try {
      const response = await api.get<any>(
        `${API_PATHS.retrievePondooStatus}?idActiveNexusFilter=${params.idActiveNexusFilter}&fullState=true&planningDate=${params.planningDate}`,
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },
);
