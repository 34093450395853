import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  zoomIn,
  zoomInOnVerticalView,
  zoomOut,
  zoomOutOnVerticalView,
} from "../../store/timeline";
import ganttCHartValues from "../../utils/ganttChartValues";
import IconButtonComponent from "../UI/buttons/IconButton";
import { ReactComponent as ZoomInIcon } from "../../assets/icons/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "../../assets/icons/zoom-out.svg";
import zoomStyles from "../../styles/ganttChart/zoom.module.css";
const ZoomComponent = () => {
  const dispatch = useAppDispatch();
  const { ganttChartDirection } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const { timelineRange, pixelPerMinute } = useAppSelector(
    (state) => state.timelineSlice
  );

  const handleZoomIn = () => {
    if (ganttChartDirection === "HORIZONTAL") {
      dispatch(zoomIn(ganttCHartValues.zoomInHours));
    } else {
      const pixelsFromTop = document.getElementById(
        "vertical-ganttChart-svg-wrapper"
      )?.scrollTop;
      dispatch(zoomInOnVerticalView(pixelsFromTop!));
    }
  };
  const handleZoomOut = () => {
    const pixelsFromTop = document.getElementById(
      "vertical-ganttChart-svg-wrapper"
    )?.scrollTop;
    dispatch(zoomOutOnVerticalView(pixelsFromTop!));
  };
  return (
    <>
      {timelineRange && (
        <div className={zoomStyles.zoomWrapper}>
          <IconButtonComponent
            width={20}
            disable={timelineRange!.hoursInView <= 2}
            id="zoom-in"
            icon={<ZoomInIcon width={16} />}
            onClick={handleZoomIn}
          />
          <IconButtonComponent
            width={20}
            // temporary. Will be updated on card related to change in triggering KPI bar.
            disable={pixelPerMinute === 0.66}
            id="zoom-out"
            icon={<ZoomOutIcon width={16} />}
            onClick={handleZoomOut}
          />
        </div>
      )}
    </>
  );
};

export default ZoomComponent;
