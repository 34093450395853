import labels from "../../utils/labels";
import KPIScaleWithData from "../UI/kpi/KPIScaleWithData";
import KpiCard from "./KpiCard";
import { ReactComponent as MedalIcon } from "../../assets/icons/medal.svg";
import { useAppSelector } from "../../store/hooks";
import colors from "../../utils/colors";
import { useEffect, useState } from "react";
import { KpiPlan } from "../../types/kpi";
import ComparisonChip from "../UI/kpi/ComparisonChip";
import comparisonUtil from "../../utils/comparisonUtil";

export type ScaleColor = {
  backgroundColor: string;
  borderColor: string;
};

const CitizenServiceCard = ({
  kpiList,
  compare,
  compareKpiList,
}: {
  kpiList: KpiPlan;
  compare: boolean;
  compareKpiList: KpiPlan;
}) => {
  const { kpiData } = useAppSelector((state) => state.kpiCollectionSlice);
  const [continuityColor, setContinuityColor] = useState<ScaleColor>();
  const [rulesColor, setRulesColor] = useState<ScaleColor>();
  const [preferencesColor, setPreferencesColor] = useState<ScaleColor>();

  useEffect(() => {
    if (kpiData) {
      setContinuityColor(
        colors.getContinuityAndPreferenceScaleColor(
          Math.round((kpiList.continuity / 10) * 100)
        )
      );
      setRulesColor(colors.getRulesScaleColor(kpiList.percentCompliedRules));
      setPreferencesColor(
        colors.getContinuityAndPreferenceScaleColor(
          kpiList.percentCompliedPreferences
        )
      );
    }
  }, [kpiData]);

  return (
    <KpiCard
      title={labels.citizenService}
      icon={<MedalIcon />}
      classes="w-full h-[323px]"
    >
      {kpiData?.pondooDashboard && (
        <div className="flex gap-spacing-xl flex-col">
          {continuityColor && (
            <KPIScaleWithData
              title="Kontinuitet"
              value={comparisonUtil.getRoundedValue(kpiList.continuity)}
              percent={Math.round((kpiList.continuity / 10) * 100)}
              color={continuityColor}
            >
              {compare && (
                <ComparisonChip
                  positiveIndicator="POSITIVE"
                  value={comparisonUtil.getRoundedComparison(
                    kpiList.continuity,
                    compareKpiList.continuity
                  )}
                  id={compareKpiList.continuity}
                />
              )}
            </KPIScaleWithData>
          )}
          {rulesColor && (
            <KPIScaleWithData
              title="Overholdelse af regler"
              value={`${kpiList.percentCompliedRules}%`}
              percent={kpiList.percentCompliedRules}
              color={rulesColor}
            >
              {compare && (
                <ComparisonChip
                  positiveIndicator="POSITIVE"
                  value={
                    kpiList.percentCompliedRules -
                    compareKpiList.percentCompliedRules
                  }
                  id={compareKpiList.percentCompliedRules}
                  unit="%"
                />
              )}
            </KPIScaleWithData>
          )}
          {preferencesColor && (
            <KPIScaleWithData
              title="Overholdelse af præferencer"
              value={`${kpiList.percentCompliedPreferences}%`}
              percent={kpiList.percentCompliedPreferences}
              color={preferencesColor}
            >
              {compare && (
                <ComparisonChip
                  positiveIndicator="POSITIVE"
                  value={
                    kpiList.percentCompliedPreferences -
                    compareKpiList.percentCompliedPreferences
                  }
                  id={compareKpiList.percentCompliedPreferences}
                  unit="%"
                />
              )}
            </KPIScaleWithData>
          )}
        </div>
      )}
    </KpiCard>
  );
};

export default CitizenServiceCard;
