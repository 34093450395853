import { useAppSelector } from "../store/hooks";
import WorkTimeCard from "../components/kpiCards/WorkTime";
import CitizenServiceCard from "../components/kpiCards/CitizenServiceCard";
import KeyNumbersCard from "../components/kpiCards/KeyNumbersCard";
import PlannedTimeCard from "../components/kpiCards/PlannedTimeCard";
import {
  useNexusPlanStatus,
  usePondooPlanStatus,
} from "../store/serverAsyncJobs/hooks";
import { Spinner } from "../components/UI/Spinner";
import PondooPlanStatusBar from "../components/blackRoundInfoComponents/PondooPlanStatusBar";

export default function KPIPage() {
  const { kpiData, comepareWithNexus } = useAppSelector(
    (state) => state.kpiCollectionSlice,
  );

  const pondooStatus = usePondooPlanStatus();
  const nexusStatus = useNexusPlanStatus();

  return (
    <article className="flex w-full gap-[var(--spacing-sm)]">
      {(nexusStatus.isLoading || pondooStatus.isLoading) &&
        !kpiData?.pondooDashboard && (
          <div
            className="flex items-center justify-center"
            style={{ width: "100%", height: "100%" }}
          >
            <Spinner />
          </div>
        )}
      {kpiData?.pondooDashboard && (
        <>
          <div className="flex flex-col w-[80%] gap-[var(--spacing-sm)]">
            <>
              <CitizenServiceCard
                kpiList={kpiData.pondooDashboard.plan}
                compare={comepareWithNexus}
                compareKpiList={kpiData.nexusDashboard.plan}
              />
              <div className="flex gap-[var(--spacing-sm)] h-[389px]">
                <>
                  <KeyNumbersCard
                    kpiList={kpiData.pondooDashboard.otherKpi}
                    compare={comepareWithNexus}
                    compareKpiList={kpiData.nexusDashboard.otherKpi}
                  />
                  <PlannedTimeCard
                    kpiList={kpiData.pondooDashboard.plannedTime}
                    compare={comepareWithNexus}
                    compareKpiList={kpiData.nexusDashboard.plannedTime}
                  />
                </>
              </div>
            </>
          </div>
          <WorkTimeCard
            kpiList={kpiData.pondooDashboard.workTime}
            compare={comepareWithNexus}
            compareKpiList={kpiData.nexusDashboard.workTime}
          />
        </>
      )}
      <PondooPlanStatusBar />
    </article>
  );
}
