import { useAppDispatch } from "../../store/hooks";
import BlackRoundWrapper from "../UI/ColouredRoundWrapper";
import CirclularStatusIcon from "../UI/CirclularStatusIcon";
import labels from "../../utils/labels";
import StandardButton from "../UI/buttons/StandardButton";
import podooStatusBarStyles from "../../styles/pondooStatusBar.module.css";
import { ReactComponent as StarsIcon } from "../../assets/icons/white-stars.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { useEffect, useRef, useState } from "react";
import { PondooJobStatus } from "../../types/ganttChart";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {
  PondooPlanStatus,
  usePondooPlanStatus,
} from "../../store/serverAsyncJobs/hooks";
import {
  calculateTimeAgo,
  getBestTimeDisplay,
  TimeAgo,
  useTimeAgo,
} from "../../hooks/useTimeAgo";
import { initiateOptimization, stopOptimization } from "../../store/ganttChart/action";
import IconButtonComponent from "../UI/buttons/IconButton";

type PondooStatusBarObject = {
  title: string;
  status: PondooJobStatus;
  buttonTitle?: string;
};

const missingStatus: PondooStatusBarObject = {
  title: labels.pondooMissing,
  status: "MISSING",
};

const runningStatus: PondooStatusBarObject = {
  title: labels.pondooRunning,
  status: "Running",
};

const failedStatus: PondooStatusBarObject = {
  title: labels.pondooStatusError,
  buttonTitle: labels.tryAgain,
  status: "Failed",
};

const getCompletedTimeAgoLabel = (timeAgo: TimeAgo): string => {
  const timeDisplay = getBestTimeDisplay(timeAgo);
  switch (timeDisplay.unit) {
    case "days":
      return labels.dayAmount(timeDisplay.value);
    case "hours":
      return labels.hoursAmount(timeDisplay.value);
    case "minutes":
      return labels.minutesAmount(timeDisplay.value);
    case "seconds":
      return labels.secondsAmount(timeDisplay.value);
  }
};

const completedStatus = (timeAgo?: TimeAgo): PondooStatusBarObject => ({
  title: timeAgo
    ? labels.pondooCompleted(getCompletedTimeAgoLabel(timeAgo))
    : labels.pondooStatusCalculating,
  status: "Completed",
  buttonTitle: labels.update,
});

const outdatedStatus = (): PondooStatusBarObject => ({
  title: labels.pondooNotUpdated,
  status: "OUTDATED",
  buttonTitle: labels.update,
});

const determinePondooStatusBarState = (
  status: PondooPlanStatus,
  timeAgo: TimeAgo | null | undefined,
  isOutdated: boolean,
): PondooStatusBarObject => {
  if (!status.isReady && !status.isLoading && !status.isError) {
    return missingStatus;
  }

  if (status.isLoading) {
    return runningStatus;
  }
  if (status.isReady) {
    if(isOutdated) {
      return outdatedStatus();
    }

    timeAgo = !!timeAgo
      ? timeAgo
      : calculateTimeAgo(status.job?.completedDateTime);
    return completedStatus(timeAgo!);
  }
  if (status.isError) return failedStatus;

  // Default case
  return missingStatus;
};

const PondooPlanStatusBar = () => {
  const dispatch = useAppDispatch();
  const pondooPlanStatus = usePondooPlanStatus();
  const timeAgo = useTimeAgo(pondooPlanStatus?.job?.completedDateTime);
  const isOutdated = !!pondooPlanStatus?.job?.outdatedDateTime;
  const [pondooStatusBarObject, setPondooStatusBarObject] =
    useState<PondooStatusBarObject>();

  useEffect(() => {
    setPondooStatusBarObject(
      determinePondooStatusBarState(pondooPlanStatus, timeAgo, isOutdated),
    );
  }, [pondooPlanStatus, timeAgo]);

  // Functions
  const updatePlan = (idScenario: number) => {
    dispatch(initiateOptimization(idScenario));
  };

  const buttonAction = () => {
    if (pondooPlanStatus?.idScenario) {
      if (pondooPlanStatus.isReady || pondooPlanStatus.isError) {
        updatePlan(pondooPlanStatus.idScenario);
      }
    }
  };


  const abortOptimization = () => {

    if (pondooPlanStatus?.idScenario) {
      if (pondooPlanStatus.isLoading) {
        dispatch(stopOptimization(pondooPlanStatus.idScenario));
      }
    }
  };

  //Drawing the statusBar based on hover state
  const [unfoldStatusBar, setUnfoldStatusBar] = useState<boolean>(false);

  const statusTitle = useRef<HTMLDivElement>(null);
  const runningBar = useRef<HTMLDivElement>(null);
  const statusButton = useRef<HTMLDivElement>(null);

  const getStatusTitleWidth = () => {
    const baseWidth = 60 + 20; // width of circle + padding
    const titleWidth = statusTitle.current!.offsetWidth; //width pf title <p>
    const widthOfRunningBar = runningBar.current
      ? runningBar.current.offsetWidth + 10
      : 0;
    const buttonWidth = statusButton.current
      ? statusButton.current.offsetWidth + 63
      : 0; // width of button + padding
    return `${baseWidth + titleWidth + buttonWidth + widthOfRunningBar}px `;
  };
  return (
    <BlackRoundWrapper
      bgColor="var(--col-black)"
      height="60px"
      width={unfoldStatusBar ? getStatusTitleWidth() : "60px"}
      classes={`${podooStatusBarStyles.nexusPlanInfo} ${unfoldStatusBar ? "justify-between" : "justify-start"}`}
      onMouseEnter={() => {
        setUnfoldStatusBar(true);
      }}
      onMouseLeave={() => {
        setUnfoldStatusBar(false);
      }}
    >
      {pondooStatusBarObject && (
        <>
          <div className="flex items-center gap-[10px] justofy-start">
            <span className="flex items-center justify-center w-[30px]">
              <CirclularStatusIcon status={pondooStatusBarObject?.status} />
            </span>
            <p
              className={`${unfoldStatusBar ? "visible" : "invisible"} text-nowrap`}
              ref={statusTitle}
            >
              {pondooStatusBarObject.title}
            </p>
          </div>
          {pondooStatusBarObject.buttonTitle && (
            <StandardButton
              onClick={() => {
                buttonAction();
              }}
              variant="WHITE"
              icon={<StarsIcon />}
            >
              <span ref={statusButton}>
                {pondooStatusBarObject.buttonTitle}
              </span>
            </StandardButton>
          )}
          {(pondooStatusBarObject.status === "Running" ||
            pondooStatusBarObject.status === "NotScheduled" ||
            pondooStatusBarObject.status === "Scheduled") && (
            <div className="flex items-center" ref={runningBar}>
              <Box sx={{ width: "200px" }}>
                <LinearProgress
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "30px",
                    height: "8px",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "var(--col-lavendar-dark)",
                    },
                  }}
                />
              </Box>
              <IconButtonComponent
                id="x"
                icon={<XIcon />}
                onClick={abortOptimization}
              ></IconButtonComponent>
            </div>
          )}
        </>
      )}
    </BlackRoundWrapper>
  );
};

export default PondooPlanStatusBar;

// const stopOptimization = () => {};

// const { pondooStatus, pondooStatusError } = useAppSelector(
//   (state) => state.ganttChartSlice,
// );
// const updatePlan = () => {
//   console.log("Update plan");
// };
// const getDifferenceInHours = () => {
//   return Math.floor(
//     (new Date().getTime() -
//       new Date(pondooStatus!.data.completedDateTime as string).getTime()) /
//       (1000 * 60 * 60),
//   );
// };

// const getStatusBasedOnUpdateDate = (): JobStatus | "OUTDATED" => {
//   if (getDifferenceInHours() > 1) return "OUTDATED";
//   else return "Completed";
// };
//
// const [pondooStatusBarObject, setPondooStatusBarObject] =
//   useState<PondooStatusBarObject>();

// const { selectedNexusFilterDate, selectedActiveNexusFilter } = useAppSelector(
//   (state) => state.nexusFiltersSlice,
// );

// useEffect(() => {
//   if (timeoutRef.current) {
//     clearTimeout(timeoutRef.current);
//   }
// }, [selectedNexusFilterDate, selectedActiveNexusFilter]);
//
// const timeoutRef = useRef<NodeJS.Timeout | null>(null);

// useEffect(() => {
//   if (pondooStatusError) {
//     setPondooStatusBarObject({
//       title: labels.pondooStatusError,
//       buttonTitle: labels.tryAgain,
//       status: "Failed",
//     });
//   } else if (!pondooStatus || !pondooStatus.data) {
//     setPondooStatusBarObject({
//       title: labels.pondooMissing,
//       buttonTitle: labels.pondooMissing,
//       status: "MISSING",
//     });
//   } else {
//     const jobStatus = pondooStatus.data.jobStatus;
//     if (jobStatus === "Completed") {
//       const newStatus = getStatusBasedOnUpdateDate();
//       setPondooStatusBarObject({
//         title: labels.pondooCompleted(getDifferenceInHours()),
//         buttonTitle: newStatus === "OUTDATED" ? labels.update : undefined,
//         status: newStatus,
//       });
//     } else if (jobStatus === "Failed") {
//       setPondooStatusBarObject({
//         title: labels.pondooStatusError,
//         buttonTitle: labels.tryAgain,
//         status: jobStatus,
//       });
//     } else if (
//       jobStatus === "NotScheduled" ||
//       jobStatus === "Scheduled" ||
//       jobStatus === "Running"
//     ) {
//       setPondooStatusBarObject({
//         title: labels.pondooRunning,
//         status: jobStatus,
//       });
//
//     }
//   }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [pondooStatus, pondooStatusError]);

// const {} = usePondooDataStatus(fil)
