import { useAppSelector } from "../../../store/hooks";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import UnplannedTasks from "./UnplannedTasks";
import VerticalGanttChartTaskWrapper from "./VerticalGanttChartTaskWrapper";
import { Fragment } from "react";

const VerticalGanttChartContent = ({
  unplannedTaskWidth,
  unplannedColumnWidth,
}: {
  unplannedTaskWidth: number;
  unplannedColumnWidth: number;
}) => {
  const { verticalColumnWidth, ganttChartData } = useAppSelector(
    (state) => state.ganttChartSlice
  );

  const { timelineRange } = useAppSelector((state) => state.timelineSlice);

  return (
    <>
      {/* Unplanned tasks */}
      {ganttChartData && timelineRange && (
        <UnplannedTasks
          taskWidthPx={unplannedTaskWidth}
          unplannedTasks={ganttChartData.generalGanttChartInfo.unplannedTasks}
        />
      )}
      {/* Planned Tasks */}
      {ganttChartData &&
        timelineRange &&
        ganttChartData.employees.map((employee, index) => (
          <Fragment key={index}>
            <VerticalGanttChartTaskWrapper
              key={`tasks-${index}`}
              employee={employee}
              xPosition={
                ganttChartVerticalValues.verticalViewHeaderColumn +
                unplannedColumnWidth +
                index * verticalColumnWidth
              }
            />
          </Fragment>
        ))}
    </>
  );
};

export default VerticalGanttChartContent;
