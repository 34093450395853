import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
import {
  hideTaskHoverBox,
  openDrawer,
  showTaskHoverBox,
} from "../../../store/drawer";
import { Employee, WorkTask } from "../../../types/ganttChart";
import taskStyles from "../../../styles/ganttChart/tasks.module.css";
import DependencyChip from "../../UI/chips/DependencyChip";
import ViolationChip from "../../UI/ViolationChip";
import { Fragment, useEffect, useState } from "react";
import { setHighlightedTasks } from "../../../store/search";
import ganttChartService from "../../../service/ganttChartService";
import TaskViolationBox from "./TaskViolationBox";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning_yellow.svg";
import { HighlightTasks } from "../../../utils/highlightUtil";

const VerticalWorkTask = ({
  id,
  xPosition,
  yPosition,
  width,
  task,
  height,
  employee,
  hideViolations,
}: {
  id?: number;
  xPosition: number;
  yPosition: number;
  width: number;
  task: WorkTask;
  height: number;
  employee?: Employee;
  hideViolations?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const highlightedTaskIds = useAppSelector(
    (state) => state.searchSlice.highlightedTaskIds
  );
  const { pixelPerMinute, timelineRange } = useAppSelector(
    (state) => state.timelineSlice
  );

  const allowedNumberOfLinesOfText = Math.floor((height! - 5) / 15);

  // const isHighlightedByLink = (): boolean => {
  //   const dependencyIds = task.taskDependencies.flatMap((td) => [
  //     td.idPrerequisiteTask,
  //     td.idDependentTask,
  //   ]);
  //   return dependencyIds.some((id) => highlightedTaskIds.includes(id));
  // };

  const [taskExceedsEndOfShift, setTaskExceedsEndOfShift] =
    useState<boolean>(false);
  const [violationHeight, setViolationHeight] = useState<number>();
  const [fullTaskViolation, setFullTaskViolation] = useState<boolean>(false);

  useEffect(() => {
    if (
      employee &&
      new Date(task.end).getTime() > new Date(employee?.shiftEndTime).getTime()
    ) {
      setTaskExceedsEndOfShift(true);
      const violationHeight =
        ganttChartService.timeDifferenceInMinutes(
          employee!.shiftEndTime,
          task.end
        ) * pixelPerMinute;

      setViolationHeight(violationHeight);
      if (violationHeight >= height) {
        setFullTaskViolation(true);
      }
    }
  }, [pixelPerMinute]);

  return (
    <>
      {verticalColumnWidth && height && (
        <>
          <foreignObject
            x={xPosition + 2}
            y={yPosition}
            width={width}
            height={height}
            onMouseEnter={() => {
              dispatch(
                showTaskHoverBox({
                  type: "WORKTASK",
                  task: task,
                  employee: employee,
                })
              );
            }}
            onMouseLeave={() => {
              dispatch(hideTaskHoverBox());
            }}
          >
            <div
              id={id?.toString()}
              onClick={() => {
                if (
                  highlightedTaskIds.includes(task.idTask) ||
                  highlightedTaskIds.length === 0
                ) {
                  dispatch(
                    openDrawer({
                      task: task,
                      employeeName: employee?.fullName,
                    })
                  );
                } else {
                  dispatch(setHighlightedTasks([]));
                }
              }}
              className={taskStyles.verticalWorkTask}
              style={{
                zIndex: 1000,
                borderLeft:
                  task.taskStatusCode === "NOT_SCHEDULED"
                    ? "4px solid var(--col-dirt-rose-dark)"
                    : "4px solid var(--col-after-eight)",
                borderBottom:
                  task.taskStatusCode === "NOT_SCHEDULED"
                    ? "1px solid var(--col-dirt-rose-dark)"
                    : "1px solid var(--col-after-eight)",
                backgroundColor:
                  task.taskStatusCode === "NOT_SCHEDULED"
                    ? "var(--col-dirt-rose)"
                    : "var(--col-after-eight-30)",
                opacity: HighlightTasks.getOpacity(
                  highlightedTaskIds,
                  task.idTask
                ),
              }}
            >
              {
                <p className={taskStyles.verticalTaskText}>
                  <b>{task.client.description}</b>
                </p>
              }
              {allowedNumberOfLinesOfText > 1 && task.subTasks && (
                <ul className={taskStyles.taskList}>
                  {task.subTasks.map((task, index) => (
                    <Fragment key={index}>
                      {allowedNumberOfLinesOfText > index + 1 && (
                        <li className={taskStyles.verticalTaskText}>
                          <div className={ganttChartStyles.bulletPoint}></div>
                          {task.description}
                        </li>
                      )}
                    </Fragment>
                  ))}
                </ul>
              )}
              {/* Todo: come back to, when design has made prioritization rules. */}
              <div className="flex flex-wrap gap-[var(--spacing-xs)] absolute bottom-[var(--spacing-sm)] right-[var(--spacing-xs)] justify-end">
                {!hideViolations &&
                  task.violations &&
                  task.violations.length > 0 && (
                    <ViolationChip height={14} width={14}></ViolationChip>
                  )}
                {task.taskDependencies && task.taskDependencies.length > 0 && (
                  <DependencyChip
                    dependencyArray={task.taskDependencies}
                    key={task.idTask}
                  />
                )}
                {(!task.startLocation || !task.endLocation) &&
                  task.taskDependencies.length > 0 && (
                    <WarningIcon height={14} width={14} />
                  )}
              </div>
            </div>
          </foreignObject>
          {taskExceedsEndOfShift && violationHeight && (
            <TaskViolationBox
              xPosition={xPosition}
              yPosition={yPosition}
              violationHeight={violationHeight}
              height={height}
              width={width}
              shiftEndTime={employee!.shiftEndTime}
              fullTaskViolation={fullTaskViolation}
            />
          )}
        </>
      )}
    </>
  );
};

export default VerticalWorkTask;
