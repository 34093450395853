import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { Stomp, IFrame, Message, CompatClient } from "@stomp/stompjs";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import SockJS from "sockjs-client";
import { addOrUpdateJob } from "../store/serverAsyncJobs";
import { WebSocketMessageSideEffectService } from "../service/WebSocketMessageSideEffectService";
import { AuthService } from "../service/api";
import { setGanttChartErrorMessage } from "../store/ganttChart";
import AppInitializationService from "../service/AppInitializationService";

// Messaging stuff

export type NexusGanttChartDataReadyResponse = {
  idActiveNexusFilter: number;
  idPlanningDate: number;
};

export type Job = {
  id: number;
  jobRef: string;
  createdDateTime: string;
  updatedDateTime: string;
  completedDateTime: string;
  jobStatus: string;
  idResponsibleUser: number;
  idScenario: number;
  outdatedDateTime: string | null;
  sentToNexusDateTime: string | null;
};

export type MessageType =
  | "NexusLandingProgressOptimizationInitiated"
  | "NexusLandingProgressOptimizationFinished"
  | "NexusLandingDataRetrievalAlreadyRunningMessage"
  | "NexusLandingProgressDataRetrievalInitiatedMessage"
  | "NexusLandingProgressNexusDataReadyMessage"
  | "NexusLandingProgressDataRetrievalFailedMessage";

export type MessageEnvelope<T> = {
  bridgeMessageType: MessageType;
  jobId: string;
  data: T;
};

export type NexusLandingProgressOptimizationInitiatedMessage =
  MessageEnvelope<Job> & {
    bridgeMessageType: "NexusLandingProgressOptimizationInitiated";
  };

export type NexusLandingProgressOptimizationFinishedMessage =
  MessageEnvelope<Job> & {
    bridgeMessageType: "NexusLandingProgressOptimizationFinished";
  };

export type NexusLandingProgressNexusDataReadyMessage =
  MessageEnvelope<NexusGanttChartDataReadyResponse> & {
    bridgeMessageType: "NexusLandingProgressNexusDataReadyMessage";
  };

export type NexusLandingDataRetrievalAlreadyRunningMessage =
  MessageEnvelope<void> & {
    bridgeMessageType: "NexusLandingDataRetrievalAlreadyRunningMessage";
  };

export type NexusLandingProgressDataRetrievalInitiatedMessage =
  MessageEnvelope<void> & {
    bridgeMessageType: "NexusLandingProgressDataRetrievalInitiatedMessage";
  };

export type NexusLandingProgressDataRetrievalFailedMessage =
  MessageEnvelope<string> & {
    bridgeMessageType: "NexusLandingProgressDataRetrievalFailedMessage";
  };

export type WebSocketMessagePayload =
  | NexusLandingProgressOptimizationInitiatedMessage
  | NexusLandingProgressNexusDataReadyMessage
  | NexusLandingProgressOptimizationFinishedMessage
  | NexusLandingDataRetrievalAlreadyRunningMessage
  | NexusLandingProgressDataRetrievalInitiatedMessage
  | NexusLandingProgressDataRetrievalFailedMessage;

// Context provider

export type WebSocketContextType = {
  client: CompatClient | null;
  readyState: number;
};

const HEARTBEAT_INTERVAL = 240000; // 4 minutes (before 5-minute token expiry)
const WebSocketContext = createContext<WebSocketContextType>({
  client: null,
  readyState: WebSocket.CLOSED,
});

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const interceptorIsReady = useAppSelector(
    (state) => state.apiConfigurationState.interceptorIsReady,
  );
  const clientRef = useRef<CompatClient | null>(null);
  const heartbeatIntervalRef = useRef<NodeJS.Timeout>();
  const sideEffectService = new WebSocketMessageSideEffectService(dispatch);

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) return null;

    try {
      const tokens = await AuthService.refreshConcreteToken(refreshToken);
      if (tokens) {
        localStorage.setItem("access_token", tokens.accessToken);
        localStorage.setItem("refresh_token", tokens.refreshToken);
        return tokens.accessToken;
      }
    } catch (error) {
      console.error("Token refresh failed:", error);
    }
    return null;
  };

  const connect = (): (() => void) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) return () => {};

    const wsRoot = window._env_.REACT_APP_WS_URL;
    const socket = new SockJS(wsRoot);
    const client = Stomp.over(socket);

    if (process.env.NODE_ENV === "production") {
      client.debug = () => {};
    }

    // Configure heartbeat to be more frequent than token expiry
    client.heartbeatIncoming = HEARTBEAT_INTERVAL;
    client.heartbeatOutgoing = HEARTBEAT_INTERVAL;

    // @ts-ignore
    client.connect(
      { Authorization: `Bearer ${accessToken}` },
      () => {
        clientRef.current = client;

        // Subscribe to messages
        client.subscribe("/bridge", (message) => {
          const payload = JSON.parse(message.body);
          dispatch(addOrUpdateJob(payload));
          sideEffectService.handleSideEffects(payload);
        });

        // Set up token refresh on heartbeat
        heartbeatIntervalRef.current = setInterval(async () => {
          const newToken = await refreshToken();
          if (newToken && client.connected) {
            // Reconnect with new token
            client.disconnect(() => {
              connect();
            });
          }
        }, HEARTBEAT_INTERVAL);
      },
      async (error: IFrame) => {
        console.error("STOMP error:", error);
        // On connection error, try to refresh token and reconnect
        const newToken = await refreshToken();
        if (newToken) {
          setTimeout(connect, 2000);
        }
      },
    );

    return () => {
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
      if (client.connected) {
        client.disconnect();
      }
      clientRef.current = null;
    };
  };

  useEffect(() => {
    if (!interceptorIsReady) return;
    const cleanup = connect();
    return () => {
      cleanup();
    };
  }, [interceptorIsReady]);

  return (
    <WebSocketContext.Provider
      value={{
        client: clientRef.current,
        readyState: clientRef.current?.connected
          ? WebSocket.OPEN
          : WebSocket.CLOSED,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
