import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ganttChartStyles from "../../styles/ganttChart/ganttChart.module.css";
import VerticalGanttChartContent from "./verticalGanttChart/VerticalGanttChartContent";
import { useEffect, useRef, useState } from "react";
import useGanttChartPlacement from "../../hooks/useGanttChartPlacement";
import {
  setUnplannedColumnWidth,
  setVerticalColumnWidth,
  setVerticalSvgWidth,
} from "../../store/ganttChart";
import SharedTimelineComponent from "./horizontalGanttChart/header/SharedTimelineComponent";
import VerticalTitleTextRow from "./verticalGanttChart/VerticalTitleTextRow";
import VerticalGanttChartSeparationLines from "./verticalGanttChart/VerticalGanttChartSeparationLines";
import HoverBox from "./hover/HoverBox";
import { hideTaskHoverBox } from "../../store/drawer";
import BackgroundColumns from "./verticalGanttChart/BackgroundColumns";
import { zoomInOnVerticalView } from "../../store/timeline";
import { ganttChartVerticalValues } from "../../utils/ganttChartValues";
import KpiRows from "./verticalGanttChart/KpiHeader/KpiRows";
import UnplannedTaskChartPositionAllocationService from "../../service/UnplannedTaskChartPositionAllocationService";

const VerticalGanttWrapper = () => {
  const svgWrapper = useRef<HTMLDivElement>(null);
  const svgTitleRow = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { windowWidth } = useGanttChartPlacement();
  const {
    verticalColumnWidth,
    verticalSvgWidth,
    translateXValueInPercent,
    ganttChartData,
    unplannedColumnWidth,
  } = useAppSelector((state) => state.ganttChartSlice);
  const { pixelsPerHour, timelineRange, distanceFromTop } = useAppSelector(
    (state) => state.timelineSlice
  );
  const { taskIsHovered } = useAppSelector((state) => state.modalSlice);
  const [containerWidthOffset, setContainerWidthOffset] = useState<number>(0);
  const [svgWrapperWidth, setSvgWrapperWidth] = useState<number>(0);
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const [unplannedTaskWidthPx, setUnplannedTaskWidthPx] = useState<number>(0);

  useEffect(() => {
    if (timelineRange && pixelsPerHour && svgWrapper.current) {
      svgWrapper.current.scrollTo({
        top:
          new Date(timelineRange.firstVisibleHour).getHours() * pixelsPerHour -
          pixelsPerHour / 2,
        left: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineRange, svgWrapper.current, pixelsPerHour]);

  useEffect(() => {
    if (distanceFromTop && svgWrapper.current) {
      svgWrapper.current.scrollTo({
        top: distanceFromTop,
        left: 0,
      });
    }
  }, [distanceFromTop]);

  useEffect(() => {
    if (svgWrapper.current) {
      const verticalSvgWrapperWidth = svgWrapper.current.clientWidth;
      setSvgWrapperWidth(verticalSvgWrapperWidth);
      setContainerWidthOffset(
        svgWrapper.current.offsetWidth - svgWrapper.current.clientWidth
      );
    }
  }, [windowWidth, svgWrapperWidth, verticalColumnWidth]);

  /* UseEffect to define both width of each column and of svgElement as a whole. */
  useEffect(() => {
    if (svgWrapperWidth && ganttChartData) {
      /* Everytime either the data, the width of the unplanned column or the width of the window changes, 
      we need to find the width of the div surrounding the svg and the number of employees to calculate svgWidth and VerticalColumnWidth.  */

      const ganttChartPlottingPoints =
        UnplannedTaskChartPositionAllocationService.getUnplannedDataPointsForPlotting(
          ganttChartData,
          svgWrapperWidth
        );

      if (
        ganttChartPlottingPoints.numberOfUnplannedFittingDefaultWidth >
        ganttChartPlottingPoints.highestGanttChartPosition
      ) {
        dispatch(
          setUnplannedColumnWidth(
            ganttChartPlottingPoints.defaultWidthIfEquallyDivided
          )
        );
        setUnplannedTaskWidthPx(
          ganttChartPlottingPoints.defaultWidthIfEquallyDivided /
            (ganttChartPlottingPoints.highestGanttChartPosition + 1) -
            4 / (ganttChartPlottingPoints.highestGanttChartPosition + 1)
        );
      } else {
        const unplannedColumnWidth =
          (ganttChartVerticalValues.unplannedMinWidth + 1) *
            (ganttChartPlottingPoints.highestGanttChartPosition + 1) +
          4; //the 4 is just for buffer around.
        dispatch(setUnplannedColumnWidth(unplannedColumnWidth));

        setUnplannedTaskWidthPx(ganttChartVerticalValues.unplannedMinWidth);
      }

      if (unplannedColumnWidth) {
        const pixelsAvailableInViewForEachEmployeeColumn =
          (svgWrapperWidth -
            unplannedColumnWidth -
            ganttChartVerticalValues.verticalViewHeaderColumn) /
          ganttChartPlottingPoints.numberOfEmployees;

        /* If it is possible to fit all employee columns into the view, 
    set the global width of the svg to the size of the defaultWrapper,
     and set the columnWidth as the width available for each.
    Else, we need to expand the svg view to overflow the window
     and each column to be the minimum allowed width for an employeeColumn */
        if (
          pixelsAvailableInViewForEachEmployeeColumn >=
          ganttChartVerticalValues.verticalViewMinColumnWidth
        ) {
          dispatch(setVerticalSvgWidth(svgWrapperWidth));
          dispatch(
            setVerticalColumnWidth(
              (svgWrapperWidth -
                unplannedColumnWidth -
                ganttChartVerticalValues.verticalViewHeaderColumn) /
                ganttChartPlottingPoints.numberOfEmployees
            )
          );
        } else {
          dispatch(
            setVerticalSvgWidth(
              unplannedColumnWidth +
                ganttChartPlottingPoints.numberOfEmployees *
                  ganttChartVerticalValues.verticalViewMinColumnWidth +
                75
            )
          );

          dispatch(
            setVerticalColumnWidth(
              ganttChartVerticalValues.verticalViewMinColumnWidth
            )
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, ganttChartData, unplannedColumnWidth, svgWrapperWidth]);

  const touchPadZoom = (event: WheelEvent) => {
    if (!event.ctrlKey) return;

    event.preventDefault();
    const { deltaY, clientX, clientY } = event;
    dispatch(zoomInOnVerticalView(-deltaY));
  };

  useEffect(() => {
    const currentWrapper = svgWrapper.current;
    if (currentWrapper) {
      currentWrapper.addEventListener("scroll", (event) => {
        dispatch(hideTaskHoverBox());
      });
      // Try the MacOS zoom thing
      currentWrapper.addEventListener("wheel", touchPadZoom);
    }

    return () => {
      if (currentWrapper) {
        currentWrapper.removeEventListener("wheel", touchPadZoom);
      }
    };
  }, [lastScrollTop]);

  const [svgHeight, setSvgHeight] = useState<number>();

  useEffect(() => {
    if (pixelsPerHour && timelineRange && svgTitleRow.current) {
      setSvgHeight(timelineRange?.fullDayHours * pixelsPerHour);
    }
  }, [pixelsPerHour, timelineRange, svgTitleRow]);

  return (
    <>
      {ganttChartData && (
        <div
          style={{
            height: "100%",
            overflowX: "scroll",
            borderTop: "1px solid var(--col-grey)",
            top: "100px",
          }}
        >
          <div
            className=" top-[1px] w-full"
            style={{
              zIndex: 100, // Use style for z-index instead of z-100 class
              position: "relative", // Ensure proper stacking context
            }}
            ref={svgTitleRow}
          >
            <VerticalTitleTextRow
              title="medarb."
              employees={ganttChartData.employees}
              solutionType={ganttChartData.solutionType}
            />
            <KpiRows employees={ganttChartData.employees} />
          </div>
          <div
            ref={svgWrapper}
            id="vertical-ganttChart-svg-wrapper"
            style={{
              height: "100%",
              borderTop: "1px solid var(--col-grey)",
              width: "fit-content",
              minWidth: `calc(100% + ${containerWidthOffset}px`,
            }}
            className={`${ganttChartStyles.verticalSvgWrapper} `}
          >
            {verticalSvgWidth && verticalColumnWidth && (
              <>
                <svg
                  id="vertical-ganttChart-svg"
                  width={verticalSvgWidth}
                  height={svgHeight}
                  style={{
                    transform: `translateX(-${translateXValueInPercent * verticalSvgWidth}px)`,
                    transition: "transform 250ms ease",
                  }}
                >
                  <g>
                    {timelineRange && (
                      <BackgroundColumns
                        verticalColumnWidth={verticalColumnWidth}
                        ganttChartData={ganttChartData}
                      />
                    )}

                    <SharedTimelineComponent />
                    <VerticalGanttChartContent
                      unplannedTaskWidth={unplannedTaskWidthPx}
                      unplannedColumnWidth={unplannedColumnWidth}
                    />
                    {pixelsPerHour && verticalColumnWidth && (
                      <VerticalGanttChartSeparationLines
                        employees={ganttChartData.employees}
                        verticalColumnWidth={verticalColumnWidth}
                        pixelsPerHour={pixelsPerHour}
                      />
                    )}
                    {taskIsHovered && <HoverBox />}
                  </g>
                </svg>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VerticalGanttWrapper;
