import { Employee} from '../../../types/ganttChart';
import ganttChartStyles from '../../../styles/ganttChart/ganttChart.module.css';

const VerticalOverlayForBlockedShift = ({
	employee,
}: {
	employee: Employee;
}) => {

	const isLockedForPlanning = employee.shiftSchedule.lockedForPlanning;

	return (
		<>
			{isLockedForPlanning && (
				<div className={ganttChartStyles.overlayForBlockedShift}></div>
			)}
		</>
	);
};

export default VerticalOverlayForBlockedShift;
