import kpiStyles from "../../../styles/UI/kpiPage.module.css";
import { ReactComponent as PositiveGreenIcon } from "../../../assets/icons/arrow_positive_green.svg";
import { ReactComponent as NegativeRedIcon } from "../../../assets/icons/arrow_negative_red.svg";
import { ReactComponent as PositiveRedIcon } from "../../../assets/icons/arrow_positive_red.svg";
import { ReactComponent as NegativeGreenIcon } from "../../../assets/icons/arrow_negative_green.svg";

type ChipColorAndIcon = {
  backgroundColor: string;
  textColor: string;
  icon?: React.ReactNode;
};

const ComparisonChip = ({
  positiveIndicator,
  value,
  id,
  unit,
}: {
  positiveIndicator: "POSITIVE" | "NEGATIVE";
  value: number;
  id: number;
  unit?: string;
}) => {
  const getChipColorAndIcon = (): ChipColorAndIcon => {
    if (value === 0) {
      return {
        backgroundColor: "var(--col-grey-2)",
        textColor: "var(--col-grey-4)",
      };
    } else if (
      (positiveIndicator === "POSITIVE" && value > 0) ||
      (positiveIndicator === "NEGATIVE" && value < 0)
    ) {
      return {
        backgroundColor: "var(--col-success-1)",
        textColor: "var(--col-success-main)",
        icon:
          positiveIndicator === "POSITIVE" ? (
            <PositiveGreenIcon height={16} width={8} />
          ) : (
            <NegativeGreenIcon height={16} width={8} />
          ),
      };
    } else {
      return {
        backgroundColor: "var(--col-error-2)",
        textColor: "var(--col-error-4)",
        icon:
          positiveIndicator === "NEGATIVE" ? (
            <PositiveRedIcon height={16} width={8} />
          ) : (
            <NegativeRedIcon height={16} width={8} />
          ),
      };
    }
  };
  return (
    <div
      className={kpiStyles.kpiChip}
      style={{
        backgroundColor: getChipColorAndIcon().backgroundColor,
        color: getChipColorAndIcon().textColor,
      }}
      id={id.toString()}
    >
      {getChipColorAndIcon().icon}
      {value === 0 ? "-" : value + (unit || "")}
    </div>
  );
};

export default ComparisonChip;
