import { menuItemWithIcon } from "../../types/common";
import { ReactComponent as OverviewIcon } from "../../assets/icons/overview.svg";
import { ReactComponent as GanttChartIcon } from "../../assets/icons/gantt-chart.svg";
import NavgationListItem from "./NavigationListItem";
import navigationStyles from "../../styles/navigation.module.css";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const PageNavigation = () => {
  const location = useLocation();

  const pageNavigationMenu: menuItemWithIcon[] = [
    {
      icon: <OverviewIcon width={16} height={16} />,
      title: "Oversigt",
      redirectUrl: "/overview" + location.search,
    },
    {
      icon: <GanttChartIcon width={16} height={16} />,
      title: "Tidsplan",
      redirectUrl: "/ganttChart" + location.search,
    },
  ];
  return (
    <section className={navigationStyles.navigationList}>
      <div className={navigationStyles.navigationListMenuWrapper}>
        {pageNavigationMenu.map((menuItem, index) => (
          <NavgationListItem menuItem={menuItem} key={index} />
        ))}
      </div>
    </section>
  );
};

export default PageNavigation;
