import React, { useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import api, { API_PATHS, AuthService } from "../service/api";
import { useAppDispatch } from "../store/hooks";
import { initiateNexusLandingDataExtraction } from "../store/ganttChart/action";
import {
  setActiveNexusFilter,
  setSelectedNexusFilterDate,
} from "../store/nexusFilters";
import AppInitializationService from "../service/AppInitializationService";
import labels from "../utils/labels";
import { AxiosResponse } from "axios";
import MediumHeader from "../components/UI/textFields/MediumHeader";
import StandardButton from "../components/UI/buttons/StandardButton";
import { Spinner } from "../components/UI/Spinner";

type NexusFilter = {
  id: number;
  idResponsibleUser: number;
  idRuleset: number;
  filter: string;
  filterId: number;
  activatedDateTime: string;
  active: boolean;
  startTime: string;
  endTime: string;
};

/**
 * Response from the "create filter if it doesn't exist" endpoint. We make sure to check if data exists for the
 * planning date in question if the filter exists.
 */
type NexusFilterResponse = {
  filter: NexusFilter;
  error?: string;
  dataAlreadyExists?: boolean;
};

const AlreadyExistsWarning = ({
  onGoToExistingClick,
  onOverwriteExistingClick,
}: {
  onGoToExistingClick: () => void;
  onOverwriteExistingClick: () => Promise<void>;
}) => {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 h-[190px] w-[620px]">
        <MediumHeader classes={"pb-2"}>
          {labels.nexusLandingDataAlreadyExistsWarningHeader}
        </MediumHeader>
        <p className={"bodyTwo pb-2"}>
          {labels.nexusLandingDataAlreadyExistsWarningBody}
        </p>
        <div className={"flex flex-row justify-end"}>
          <StandardButton
            variant={"PRIMARY"}
            classes={"mr-6"}
            onClick={onGoToExistingClick}
          >
            {labels.nexusLandingPageGoToExistingButton}
          </StandardButton>
          <StandardButton
            variant={"SECONDARY"}
            onClick={onOverwriteExistingClick}
          >
            {labels.nexusLandingPageOverwriteData}
          </StandardButton>
        </div>
      </div>
    </div>
  );
};

const NexusLandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [pageDisplayState, setPageDisplayState] = useState<
    "Spinner" | "Error" | "AlreadyExistsWarning"
  >("Spinner");

  const [pendingNexusFilterResponse, setPendingNexusFilterResponse] = useState<
    AxiosResponse<NexusFilterResponse> | undefined
  >();

  const appInitializationService = useMemo(() => {
    return new AppInitializationService(dispatch);
  }, [dispatch]);

  const hasPassedAuthChallenge = (): boolean => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.has("code");
  };

  const handleInitialLanding = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const filterId = searchParams.get("filterId");
    const date = searchParams.get("date");
    const filterName = searchParams.get("filterName");
    if (!filterId || !date || !filterName) {
      // If these aren't here, there's no reason for us to be on Nexus Landing
      console.error(
        "Navigated to /nexus-landing without filterId and date url params, redirecting to root...",
      );
      navigate("/");
      return;
    }

    await AuthService.performLogin(filterId, date, filterName);
  };

  const sanitizeLocalDate = (date: string): string => date.split("T")[0];

  const startNexusLandingDataExtraction = async (
    nexusFilterResponse: AxiosResponse<NexusFilterResponse>,
  ) => {
    const searchParams = new URLSearchParams(window.location.search);
    const filterId = searchParams.get("filterId");
    const date = searchParams.get("date");
    const filterName = searchParams.get("filterName");

    const sanitizedFilterName = decodeURIComponent(filterName ?? "");
    const sanitizedDate = date!.split("T")[0];
    // Set active filter and date before redirecting
    dispatch(
      setActiveNexusFilter({
        ...nexusFilterResponse.data.filter,
        filterName: sanitizedFilterName,
      }),
    );
    dispatch(setSelectedNexusFilterDate(sanitizedDate));
    // We have a filter, queue the data extraction
    const data = nexusFilterResponse.data.filter as NexusFilter;

    dispatch(
      initiateNexusLandingDataExtraction({
        filterId: parseInt(filterId!),
        date: sanitizeLocalDate(date!),
        pondooFilterId: data.id,
        filterName: sanitizedFilterName,
      }),
    );

    setTimeout(() => {
      navigate({
        pathname: "/",
        search: createSearchParams({
          filter: data.id.toString(),
          date: sanitizedDate,
        }).toString(),
      });
    }, 2000);
  };

  /**
   * Performs an initial hand-shake with the Pondoo application to ensure we have a filter ready. Also checks
   * if the filter already has data on the given planning date - if so, the response is stashed and the user is informed.
   */
  const initiateNexusLandingChecks = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const filterId = searchParams.get("filterId");
    const date = searchParams.get("date");
    const filterName = searchParams.get("filterName");

    const sanitizedFilterName = decodeURIComponent(filterName ?? "");
    const sanitizedDate = date!.split("T")[0];

    // Ensure we can create the Nexus filter
    const nexusFilterResponse = await api.post<NexusFilterResponse>(
      API_PATHS.createOrUpdateNexusFilter,
      {
        filterId: filterId,
        shouldDisable: false,
        displayName: sanitizedFilterName,
        date: sanitizedDate,
      },
    );

    if (!(nexusFilterResponse.status === 200)) {
      setPageDisplayState("Error");
      return;
    }

    if (nexusFilterResponse.data.dataAlreadyExists) {
      setPendingNexusFilterResponse(nexusFilterResponse);
      setPageDisplayState("AlreadyExistsWarning");
      return;
    }

    await startNexusLandingDataExtraction(nexusFilterResponse);
  };

  const handleSuccessfulAuthCheckLanding = async () => {
    const tokens = await AuthService.exchangeCodeForToken();

    if (!tokens) {
      // TODO @Tanja - do we have an error page?
      console.error("Could not authenticate with tokens");
      navigate("/notfound");
      return;
    }

    appInitializationService.configureAppInitialization(
      tokens.accessToken,
      tokens.refreshToken,
    );

    await initiateNexusLandingChecks();
  };

  useEffect(() => {
    if (hasPassedAuthChallenge()) {
      handleSuccessfulAuthCheckLanding();
    } else {
      handleInitialLanding();
    }
  }, []);

  return (
    <div
      className="flex items-center justify-center"
      style={{ width: "100%", height: "100%" }}
    >
      {{
        Spinner: <Spinner />,
        Error: <h1>{labels.pondooNexusLandingError}</h1>,
        AlreadyExistsWarning: (
          <AlreadyExistsWarning
            onGoToExistingClick={() => {
              const data = pendingNexusFilterResponse!.data;
              const searchParams = new URLSearchParams(window.location.search);
              const date = searchParams.get("date");
              const sanitizedDate = date!.split("T")[0];
              navigate({
                pathname: "/",
                search: createSearchParams({
                  filter: data.filter.id.toString(),
                  date: sanitizedDate,
                }).toString(),
              });
            }}
            onOverwriteExistingClick={async () => {
              await startNexusLandingDataExtraction(
                pendingNexusFilterResponse!,
              );
            }}
          />
        ),
      }[pageDisplayState] || <Spinner />}
    </div>
  );
};

export default NexusLandingPage;
