import StandardButton from "../UI/buttons/StandardButton";
import { ReactComponent as UploadIcon } from "../../assets/icons/black-upload.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import CircularProgress from "@mui/material/CircularProgress";
import api, { API_PATHS } from "../../service/api";
import { useAppDispatch } from "../../store/hooks";
import { setNotification } from "../../store/toastNotifications";
import React, { useEffect, useState } from "react";

const SendToNexus = ({
  idScenario,
  check,
}: {
  idScenario: number;
  check: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [callingBackend, setCallingBackend] = useState<boolean>(false);
  const [icon, setIcon] = useState<React.ReactNode>(<UploadIcon />);
  const sendToNexus = async () => {
    try {
      setCallingBackend(true);
      const body = {
        idScenario: idScenario,
      };
      const response = await api.post<any>(API_PATHS.postNexusPlan, body);
      if (response.status >= 400) {
        dispatch(
          setNotification({
            message: "Vi kunne ikke sende planen til Nexus",
            type: "send_to_nexus",
          })
        );
      }
    } catch {
      dispatch(
        setNotification({
          message: "Vi kunne ikke sende planen til Nexus",
          type: "send_to_nexus",
        })
      );
    }
    setCallingBackend(false);
  };

  useEffect(() => {
    if (check) setIcon(<CheckIcon />);
    else if (callingBackend)
      setIcon(<CircularProgress color="inherit" size={18} />);
    else setIcon(<UploadIcon />);
  }, [check, callingBackend]);

  return (
    <StandardButton
      onClick={() => sendToNexus()}
      variant="SECONDARY"
      icon={icon}
    >
      Send plan til Nexus
    </StandardButton>
  );
};

export default SendToNexus;
