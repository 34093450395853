import { DateTime } from "luxon";

class timelineService {
  static getFirstVisibleHour = (startDate: number): number => {
    const dt = DateTime.fromMillis(startDate);
    // Todo: this needs to be rewritten, if at one point, we need to handle timelines that spans days.
    let firstVisibleHour = dt.minus({ hours: 1 });
    if (firstVisibleHour.day === dt.day) {
      return firstVisibleHour.toMillis();
    } else {
      return new Date(startDate).setHours(0);
    }
  };

  static getFirstHourInFullDay = (startDate: number) => {
    const dateTime = DateTime.fromMillis(startDate);
    const threeHoursEarlier = dateTime.minus({ hours: 3 });

    return threeHoursEarlier;
  };

  static getHoursInView = (startDate: number, endDate: number) => {
    const diffInTime =
      new Date(endDate).getTime() - new Date(startDate).getTime();
    const diffInHours = diffInTime / (1000 * 60 * 60);
    return Math.round(diffInHours);
  };

  static returnDurationInMinutes = (duration: string) => {
    let durationString = duration.split("T")[1];
    let minutes = 0;

    if (durationString.includes("H")) {
      const hour = parseInt(durationString.split("H")[0]);
      minutes = parseInt(durationString.split("H")[1]) | 0;
      minutes = minutes + hour * 60;
    } else {
      minutes = parseInt(durationString.split("M")[0]);
    }

    return minutes;
  };
}

export default timelineService;
