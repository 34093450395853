import { Employee, LockShiftScheduleData, ShiftSchedule } from '../../../types/ganttChart';
import ganttChartStyles from '../../../styles/ganttChart/ganttChart.module.css';
import api, { API_PATHS } from '../../../service/api';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { ReactComponent as LockUnlockedIcon } from '../../../assets/icons/lock-unlocked.svg';
import { useDispatch } from 'react-redux';
import { updateLockedForPlanning } from '../../../store/ganttChart';
import { setNotification } from '../../../store/toastNotifications';

const VerticalTitleTextRowLock = ({
	employee,
	solutionType,
}: {
	employee: Employee;
	solutionType: 'Nexus' | 'Pondoo';
}) => {
	const dispatch = useDispatch();

	const isNexusSolution = solutionType === 'Nexus';

	const toggleLockedForPlanning = async (shiftSchedule: ShiftSchedule) => {
		if (isNexusSolution) {
			const lockedForPlanning = !shiftSchedule.lockedForPlanning;
			const idShift = shiftSchedule.idShift;

			try {
				const data: LockShiftScheduleData = {
					idShift: idShift,
					lockedForPlanning: lockedForPlanning,
				};

				const response = await api.post<any>(API_PATHS.postLockShiftSchedule, data);
				if (response) {
					dispatch(updateLockedForPlanning({ idShift, lockedForPlanning }));
				}
			} catch {
				dispatch(
					setNotification({
						message: 'Vi kunne ikke opdaterer låsning af kalenderen for medarbejderen.',
						type: 'lock_shift',
					})
				);
			}
		}
	};

	return (
		<span className={`${isNexusSolution ? ganttChartStyles.verticalTitleLockIconWithPointer : ''} absolute right-1`}>
			{employee.shiftSchedule.lockedForPlanning ? (
				<LockIcon
					height={14}
					width={14}
					onClick={() => {
						toggleLockedForPlanning(employee.shiftSchedule);
					}}
				/>
			) : (
				<LockUnlockedIcon
					height={14}
					width={14}
					onClick={() => {
						toggleLockedForPlanning(employee.shiftSchedule);
					}}
				/>
			)}
		</span>
	);
};

export default VerticalTitleTextRowLock;
