import {
  PreferrenceSwitchDataObject,
  WorkTaskPreference,
} from "../../types/ganttChart";
import drawerStyle from "../../styles/drawer.module.css";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/check.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as BlockIcon } from "../../assets/icons/block.svg";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert_yellow.svg";
import labels from "../../utils/labels";
import DrawerBlock from "./DrawerBlock";
import TrippleSwitch from "../UI/input/TrippleSwitch";
import { useEffect, useState } from "react";
import api, { API_PATHS } from "../../service/api";
import StandardHoverBox from "../UI/StandardHoverBox";
import { useAppDispatch } from "../../store/hooks";
import { setNotification } from "../../store/toastNotifications";

const EmployeePreferences = ({
  preferences,
  employeeName,
  idServiceClient,
  idTask,
}: {
  preferences: WorkTaskPreference[];
  employeeName?: string;
  idServiceClient: number;
  idTask: number;
}) => {
  const dispatch = useAppDispatch();
  const [preferrenceSwitchData, setPreferrenceSwitchData] =
    useState<PreferrenceSwitchDataObject[]>();

  useEffect(() => {
    getPreferrenceSwitchData();
  }, []);

  const getPreferrenceSwitchData = async () => {
    try {
      const response = await api.get<any>(
        `${API_PATHS.postPreferrence}?idTask=${idTask}`
      );
      setPreferrenceSwitchData(response.data.employeeFavorabilities);
    } catch {
      dispatch(
        setNotification({
          message: "Vi kunne ikke hente listen af præferencer",
          type: "get_preferrences",
        })
      );
    }
  };
  const [drawer, setDrawer] = useState<any>();
  const [topDistance, setTopDistance] = useState<any>();

  useEffect(() => {
    setDrawer(document.getElementById("pref"));
  }, []);

  useEffect(() => {
    if (drawer) setTopDistance(drawer!.getBoundingClientRect().top);
  }, [drawer]);

  const [isBlockedHovered, setBlockedIsHovered] = useState<number>(-1);
  const [isAlertHovered, setAlertIsHovered] = useState<number>(-1);
  const [isLockedHovered, setLockedIsHovered] = useState<number>(-1);

  return (
    <DrawerBlock title={labels.rules}>
      <ul className={drawerStyle.preferenceList} id="pref">
        <li className={drawerStyle.preferenceItem}>
          <p
            className={`${drawerStyle.preferenceItemName} text-grey-4 w-[150px]`}
          >
            {labels.employee}
          </p>
          <p
            className={`${drawerStyle.preferenceItemStatus} text-grey-4 w-[150px]`}
          >
            {labels.nexusRules}
          </p>
          <p
            className={`${drawerStyle.preferenceItemStatus} text-grey-4 w-[85px]`}
          >
            {labels.competencies}
          </p>
          <p
            className={`${drawerStyle.preferenceItemStatus} text-grey-4 w-[85px]`}
          >
            {labels.score}
          </p>
          <p
            className={`${drawerStyle.preferenceItemStatus} text-grey-4 w-[100px]`}
          >
            {labels.preferences}
          </p>
        </li>
        {preferences.map((preference, index) => (
          <li>
            {employeeName === preference.employeeName && (
              <p
                className="text-grey-4"
                style={{ font: "var(--font-body-3)", zIndex: 100 }}
              >
                {labels.assignedEmployee}
              </p>
            )}
            <div className={`${drawerStyle.preferenceItem}`}>
              {employeeName === preference.employeeName && (
                <div className={`${drawerStyle.preferenceSelectedItem}`}></div>
              )}
              <p className={`${drawerStyle.preferenceItemName} w-[200px]`}>
                {preference.employeeName}
              </p>
              <div className={`${drawerStyle.preferenceItemStatus} w-[90px]`}>
                {preference.lockedOnEmployee && (
                  <div className="relative inline-block">
                    <LockIcon
                      width={24}
                      height={24}
                      onMouseEnter={(e: any) => {
                        setLockedIsHovered(index);
                      }}
                      onMouseLeave={() => {
                        setLockedIsHovered(-1);
                      }}
                    />
                    {isLockedHovered === index && (
                      <StandardHoverBox text={labels.locked} />
                    )}
                  </div>
                )}
                {preference.blockedEmployee && (
                  <div className="relative inline-block">
                    <BlockIcon
                      width={24}
                      height={24}
                      onMouseEnter={(e: any) => {
                        setBlockedIsHovered(index);
                      }}
                      onMouseLeave={() => {
                        setBlockedIsHovered(-1);
                      }}
                    />
                    {isBlockedHovered === index && (
                      <StandardHoverBox text={labels.blocked} />
                    )}
                  </div>
                )}
                {preference.blockedEmployee && preference.lockedOnEmployee && (
                  <AlertIcon height={14} width={14} />
                )}
              </div>
              <div className={`${drawerStyle.preferenceItemStatus} w-[90px]`}>
                {preference.hasCompetence && (
                  <CheckmarkIcon width={24} height={24} />
                )}
              </div>
              <div className={`${drawerStyle.preferenceItemStatus} w-[90px]`}>
                {preference.score}
              </div>
              {preferrenceSwitchData && (
                <div
                  className={`${drawerStyle.preferenceItemStatus} w-[100px]`}
                >
                  {preferrenceSwitchData.map((x, index) => {
                    if (x.idEmployee === preference.idEmployee) {
                      return (
                        <TrippleSwitch
                          key={index}
                          index={index}
                          status={x.employeeFavorabilityCode}
                          idEmployee={x.idEmployee}
                          idServiceClient={idServiceClient}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </DrawerBlock>
  );
};

export default EmployeePreferences;
