import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSelectedNexusFilterDate } from "../../store/nexusFilters";
import DatePicker from "./DatePicker";
import { useSearchParams } from "react-router-dom";
import { resetGanttChartErrorMessage } from "../../store/ganttChart";
import dateUtil from "../../utils/dateUtil";

const HeaderCalendar = ({}: {}) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const URLDate = searchParams.get("date");
  const { calendarEndDate, calendarStartDate } = useAppSelector(
    (state) => state.nexusFiltersSlice
  );

  const [defaultDate, _] = useState<string | undefined>(
    URLDate ? URLDate : undefined
  );

  const sanitizeDateString = (date: string): string =>
    date.includes("T") ? date.split("T")[0] : date;

  const handleChange = (filterName: string, value?: string) => {
    dispatch(resetGanttChartErrorMessage());
    setSearchParams((prevParams) => {
      if (value) {
        prevParams.set(filterName, value);
      } else {
        prevParams.delete(filterName);
      }
      return prevParams;
    });
  };

  return (
    <DatePicker
      showArrows
      min={calendarStartDate}
      max={calendarEndDate}
      setSelectedDate={(date: string) => {
        dispatch(setSelectedNexusFilterDate(sanitizeDateString(date)));
        handleChange("date", dateUtil.getCalendarValueString(new Date(date)));
      }}
      bgColor="white"
      color="var(--col-black)"
      defaultDate={defaultDate}
    />
  );
};

export default HeaderCalendar;
