import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  MessageEnvelope,
  NexusLandingProgressDataRetrievalFailedMessage,
  NexusLandingProgressNexusDataReadyMessage,
} from "../hooks/WebSocketProvider";
import { getPlanningDates } from "../store/nexusFilters/action";
import { setGanttChartErrorMessage, setHideSpinner } from "../store/ganttChart";
import { dequeueAffectedNexusLandingPlanningDate } from "../store/nexusFilters";

export class WebSocketMessageSideEffectService {
  dispatch: ThunkDispatch<any, any, any>;
  constructor(dispatch: ThunkDispatch<any, any, any>) {
    this.dispatch = dispatch;
  }

  handleSideEffects = <T extends MessageEnvelope<any>>(message: T) => {
    switch (message.bridgeMessageType) {
      case "NexusLandingProgressOptimizationInitiated":
        break;
      case "NexusLandingProgressOptimizationFinished":
        break;
      case "NexusLandingDataRetrievalAlreadyRunningMessage":
        break;
      case "NexusLandingProgressDataRetrievalInitiatedMessage":
        break;
      case "NexusLandingProgressDataRetrievalFailedMessage":
        this.handleDataRetrievalFailureMessage(
          message as NexusLandingProgressDataRetrievalFailedMessage,
        );
        break;
      case "NexusLandingProgressNexusDataReadyMessage":
        this.handleNexusDataReadyMessage(
          message as NexusLandingProgressNexusDataReadyMessage,
        );
        break;
      default:
        break;
    }
  };

  /**
   * For historical reasons the state of the Nexus/Pondoo plans is wired into the planning dates. To show a spinner
   * accordingly, we have elsewhere set the ids of these plans to `undefined`; when we receive data, we must refresh our
   * local array of PlanningDate objects accordingly.
   * @param message
   */
  private handleNexusDataReadyMessage = (
    message: NexusLandingProgressNexusDataReadyMessage,
  ) => {
    this.dispatch(dequeueAffectedNexusLandingPlanningDate());
    this.dispatch(getPlanningDates(message.data.idActiveNexusFilter));
  };

  /**
   * Inform the client what kind of error occurred
   * @param message
   */
  private handleDataRetrievalFailureMessage = (
    message: NexusLandingProgressDataRetrievalFailedMessage,
  ) => {
    this.dispatch(setGanttChartErrorMessage(message.data));
    this.dispatch(setHideSpinner());
  };
}
